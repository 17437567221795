document.addEventListener('DOMContentLoaded', () => {
  const title = document.querySelector('.m-home-hero__title') as HTMLElement;
  const words = title?.textContent?.split(' ');
  const btnIndex = document.querySelector('.m-product-list__toggle button');
	const productList = document.querySelector('.m-product-list__wrapper');

  // use x and y mousewheel event data to navigate flickity
  function flickity_handle_wheel_event(e:any, flickity_instance:any, flickity_is_animating:any) {
    // do not trigger a slide change if another is being animated
    if (!flickity_is_animating) {    
      // pick the larger of the two delta magnitudes (x or y) to determine nav direction
      var direction = (Math.abs(e.deltaX) > Math.abs(e.deltaY)) ? e.deltaX : e.deltaY;
      
      console.log("wheel scroll ", e.deltaX, e.deltaY, direction);
      
      if (direction > 0) {
        // next slide
        flickity_instance.next();
      } else {
        // prev slide
        flickity_instance.previous();
      }
    }
  }

  // Slider
  var sliderEl = productList?.querySelector('.m-products__carousel') as HTMLElement;
  var flkty = new (window as any).Flickity( sliderEl, {
    cellAlign: 'left',
    contain: true,
    prevNextButtons: false,
    pageDots: false,
    wrapAround: true,
    imagesLoaded: true,
    autoPlay: true
  });
  flkty.on( 'pointerUp', function () {
    flkty.player.play();
  });
  var slider_is_animating = false;

  flkty.on("settle", function(index:number) {
    slider_is_animating = false;
  });

  flkty.on("select", function(index:number) {
    slider_is_animating = true;
  });

  // detect mousewheel event within carousel element
  sliderEl.onwheel = function(e) {
    flickity_handle_wheel_event(e, flkty, slider_is_animating);
  }

  // Toggle
  btnIndex?.addEventListener('click', () => {
		productList?.classList.toggle('is-hidden');
	});

  // Animate title
  if (title && words) {
    title.innerHTML = '';
    title.style.setProperty('--total-delay', `${.05 * (words.join('').length - 1)}s`);
    let count = -1;

    // Split each word into letters and wrap each letter in a span
    words.forEach((word: string, wordIndex: number) => {
      word.split('').forEach((letter, letterIndex) => {
        count++;
        const span = document.createElement('span');
        span.style.setProperty('--delay', `${0.05 * count}s`);
        span.textContent = letter;
        title.appendChild(span);
      });
      if (wordIndex < words.length - 1) {
        const spaceSpan = document.createElement('span');
        spaceSpan.textContent = ' ';
        title.appendChild(spaceSpan);
      }
    });

    // Add class to animate letters
    window.addEventListener("load", (e) => {
      setTimeout(() => {
        title?.classList.add('is-animated');
  
        setTimeout(() => {
          title?.classList.remove('is-animated');
        }, 8000);
      }, 2000);
    });
  }
});